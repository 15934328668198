.videov-chat{
	display: flex;
	flex-direction: column;
	height: 100%;
	flex-grow: 1;
	align-items: center;
	background-color: #19171c;
	padding: 5px;
}
.videov-chat__viewers{
	align-self: flex-start;
	color: #fff;
	width: 100%;
	overflow-y: auto;
}
.videov-chat__settings{
	color: #fff;
	width: 100%;
	display: flex;
}
.videov-chat__content{
	height: 53%;
	color: #fff;
	width: 100%;
	overflow-y: auto;
}
.videov-chat__message.marked{
	background-color: #e67070;
}
.videov-chat__input{
	align-self: flex-end;
	height: 17%;
	width: 100%;
}
.videov-chat__viewers__list__header{
	color: #9e3dff;
}
.videov-chat__viewer__control-btn{
	color: #9e3dff;
	cursor: pointer;
}
.videov-chat__icon{
	fill: currentColor;
}
.videov-chat__room-selector,
.videov-chat__room-selector__buttons{
	display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}
.videov-chat__room-selector__button{
	display: flex;
	cursor: pointer;
	border: 1px solid #a5a4a4;
	text-align: center;
	padding: 3px;
	color: #a5a4a4;
}
.videov-chat__room-selector__button.ative,
.videov-chat__room-selector__button:hover{
	background-color: #636262
}
.videov-chat__settings__colorsector__color{
	width: 15px;
	height: 15px;
	display: inline-block;
	margin: 0 5px 5px 0;
	cursor: pointer;
}
.videov-chat__settings__colorsector__color.selected{
	box-shadow: 0 0 0 3px #d7cceb;
}
.videov-chat__inputBaloon{
	color: #fff;
}
.videov-chat__inputBaloon__user{
	cursor: pointer;
}
.videov-chat__inputBaloon__user:hover{
	background-color: #9e3dff
}
.videov-chat__emoji__category__list{
	display: flex;
}
.videov-chat__emoji__button{
	width: 20px;
	height: 20px;
	display: inline-block;
	margin: 0 5px 5px 0;
	cursor: pointer;
	overflow: hidden;
}
.videov-chat__emoji{
	background-color: #4a4a4a;
	overflow-y: scroll;
	color: #fff;
}

/*New*/

.chat-block {
    height: 100%;
    width: 100%;
    max-width: 460px;
}

.videov-chat {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    align-items: center;
    background-color: transparent;
    padding: 0;
}

.videov-chat_header{
	width: 100%;
}
.videov-chat_title{
	margin-top: 0;
    margin-left: 0;
    color: #4B93CD;
    font-family: 'Proxima Nova Black';
    font-size: 36px;
    font-style: normal;
    font-stretch: normal;
    font-weight: 900;
    opacity: 0.27;
    word-spacing: 0;
    line-height: normal;
    margin-right: 0;
	margin-bottom: 30px;
}

.videov-chat_question{
	color: #4A4A4A;
    font-family: Proxima;
    opacity: 0.67;
    word-spacing: 0;
    line-height: 16px;
    font-size: 16px;
    padding: 0 50px 0 20px;	
	margin-bottom:12px;
}

.videov-chat__room-selector{
	padding: 0 46px 0 16px;
	justify-content: flex-start;
	height: 100%;
    max-height: 68px;
	flex-shrink: 0;
}

.videov-chat__room-selector__buttons {
    justify-content: space-between;
}

.videov-chat__room-selector__button {
    cursor: pointer;
    border: 1px solid #4B93CD;
    text-align: center;
    padding: 0;
    color: #4B93CD;
    width: 122px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    margin: 0 4px;
    flex: 1;
    font-size: 12px;
    font-family: Proxima;
	box-shadow: 0 0 0 transparent;
	background:transparent;
	position:relative;
}

.videov-chat__room-selector__button:hover {
    background: linear-gradient(167.38deg, #FAD961 -42%, #F76B1C 120.48%);
	box-shadow: 0px 0px 17px rgba(249, 186, 77, 0.578323);
	color: #fff;
	border: 1px solid transparent;
}

.videov-chat__room-selector__button.active_btn{
	background: linear-gradient(167.38deg, #FAD961 -42%, #F76B1C 120.48%);
	box-shadow: 0px 0px 17px rgba(249, 186, 77, 0.578323);
	color: #fff;
	border: 1px solid transparent;
}


.videov_btn_counter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 7px 0;
}
.videov_btn_counter span{
	color: #000000;
    font-family: Proxima;
    opacity: 0.4;
    word-spacing: 0;
    line-height: 16px;
    font-size: 12px;
    padding: 0;
    margin-bottom: 0;
}
.videov_btn_counter span:first-child{
	margin-right:5px;
}

.videov-chat__room-selector__button[role=mute]:before{
    content: '';
    position: absolute;
    right: 6px;
    top: 9px;
    display: block;
    height: 20px;
    width: 20px;
    background: url(/assets/img/videovchat/mute_mini.svg);
    background-repeat: no-repeat;
}

.videov-chat__room-selector__button[role=mute]:hover:before{
	background: url(/assets/img/videovchat/mute_mini_white.svg);
	background-repeat: no-repeat;
}

.videov-chat__room-selector__button[role=mute].active_btn:before{
	background: url(/assets/img/videovchat/mute_mini_white.svg);
	background-repeat: no-repeat;
}

.videov-chat_mes_box{
	width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 30px;
	position: relative;
	background: transparent;
}
.videov-chat__content {
	height: calc(100vh - 440px);
    color: #000;
    width: 100%;
    overflow-y: auto;
    background: #F2F2F2;
}


.videov-chat__content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(to right, #FAD961 0%,#F76B1C 100%);
    border: 4px solid transparent;
    width: 18px;
    background-clip: content-box;
}

.videov-chat__content::-webkit-scrollbar-track {
    background-color: #F2F2F2;
    border: 1px solid #4B93CD;
    width: 100%;
    border-radius: 24px;
    position: relative;
    z-index: 999999999;
}

.videov-chat__content::-webkit-scrollbar {
    width: 22px;
}
.videov-chat__content__messages{
	max-width: 430px;
    background: #fff;
    height: 100%;
    position: relative
}
.videov-chat__message__author_img{
	height: 26px;
    width: 26px;
    overflow: hidden;
    border-radius: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
	background: #000;
	margin-right: 13px;
	flex-shrink: 0;
}
.videov-chat__message__author_img img{
	max-width:100%;
}
.videov-chat__message {
	display: flex;
    align-items: flex-start;
    justify-content: flex-start;
	position:relative;
	background: #fff;
    padding: 0 20px;
}
.videov-chat__message__textbox{
	color: #4A4A4A;
    font-family: Proxima;
    opacity: 0.8;
    word-spacing: 0;
    line-height: 16px;
    font-size: 14px;
    padding: 0;
    margin-bottom: 14px;
	margin-top: 3px;
	word-break: break-word;
    padding-right: 20px;
}
.videov-chat__message_editbtn{
	position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 5px;
    height: 12px;
    margin: auto;
    bottom: 0;
	right: 20px;
	
}
.videov-chat__message_edit{
	display:none;
	padding: 8px 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.188859);
    position: absolute;
    right: 18px;
    top: 0;
    z-index: 50;
    background: #fff;
	margin: auto;
    bottom: 0;
    height: 36px;
}
.videov-chat__message_edit.active_edit{
	display:flex;
}
.videov-chat__message_reply span{
	color:#4B93CD;
	font-size:10px;
	font-family: Proxima;
}
.videov-chat__message_delete span{
	color:#D0021B;
	font-size:10px;
	font-family: Proxima;
}
.videov-chat__message_reply, .videov-chat__message_delete{
	display: flex;
    align-items: center;
    justify-content: flex-start;
	cursor:pointer;
}
.videov-chat__message_reply{
	margin-right: 10px;
}
.videov-chat__message_edit img{
	margin-right:2px;
}
.videov-chat__textarea_border_1{
	border: 1px solid rgba(75, 147, 205, 0.3);
	box-sizing: border-box;
	border-radius: 24px;
	padding:3px;
}
.videov-chat__textarea_border_2{
	border: 1px solid rgba(75, 147, 205, 0.89);
	box-sizing: border-box;
	border-radius: 24px;
	display: flex;
    justify-content: center;
    align-items: center;
}

.videov-chat_send_wrapper_user{
	width: 46px;
    height: 46px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    position: absolute;
    top: 2px;
    left: -23px;
}

.videov-chat_send_wrapper_user img{
	max-width:100%;
}
.videov-chat__input__button_send{
	background: linear-gradient(157.8deg, #FAD961 -42%, #F76B1C 120.48%);
    box-shadow: 0px 0px 17px rgba(249, 186, 77, 0.578323);
    border-radius: 23.5px;
    border: none;
    color: #fff;
    font-family: Proxima;
    font-size: 14px;
    padding: 13px 18px;
    text-transform: uppercase;
    position: absolute;
    top: 2px;
    right: -65px;
}
.videov-chat__input__button_settings{
	border: none;
    background: transparent;
    padding: 0;
    margin-left: 13px;
    margin-right: 10px;
}
.videov-chat__input {
    align-self: flex-end;
    height: auto;
    width: 100%;
    max-width: 430px;
    background: #fff;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
	padding-bottom: 25px;
}

.videov-chat__textarea{
	height: 41px;
    width: 125px;
    max-width: 300px;
    border: none;
    border-radius: 0;
    font-size: 14px;
    color: #000;
    font-family: Proxima;
    padding: 10px 0px;
    opacity: 1;
    resize: none;
    overflow-y: auto;
}

.videov-chat__textarea::-webkit-scrollbar { 
    display: none; 
}

.videov-chat__textareaWrapper{
	display: flex;
    justify-content: flex-start;
    align-items: center;
}

.videov-chat_send_wrapper{
	display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-left: 23px;
    margin-right: 65px;
}

.videov-chat__input__button_emoji{
	border: none;
    background: transparent;
    padding: 0;
    margin-left: 24px;
    margin-right: 10px;
}

.videov-chat__settings {
    color: #000;
    width: 165px;
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.188859);
    border-radius: 2px;
    position: absolute;
	bottom: 65px;
    left: 11px;
    z-index: 10;
    max-width: 165px;

}
.videov-chat__input_box{
	position:relative;
	background: #F2F2F2;
}
.videov-chat__settings__group__title{
	line-height: 16px;
    font-size: 12px;
    color: #4A4A4A;
    font-family: Proxima;
    opacity: 0.8;
    margin-bottom: 5px;
}
.videov-chat__settings__colorsector{
	display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.videov-chat__settings__group{
	padding:15px;
}

.videov-chat__settings__colorsector__color {
	width: 16px;
    height: 16px;
    border-radius: 50px;
    display: inline-block;
    margin: 0 3px 0 0;
    cursor: pointer;
    border: 2px solid #fff;
}

.videov-chat__settings__colorsector__color.selected {
    box-shadow: 0 0 0 2px rgba(75, 147, 205, 0.3);
}

#colorpicker{
	border: none;
    box-shadow: none;
    background: url(/assets/img/videovchat/colorpicker.svg);
    width: 19px;
    height: 19px;
    background-repeat: no-repeat;
    background-size: contain;
}

#emoji{
    position: absolute;
    bottom: 80px;
    left: 38px;
    max-width: 355px;
}

.videov-chat__emoji {
	background-color: #fff;
    overflow-y: auto;
    color: #fff;
    width: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.188859);
    border-radius: 2px;
    max-width: 380px;
}

.videov-chat__emoji__button {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin: 0 5px 5px 0;
    cursor: pointer;
    overflow: hidden;
    color: #000;
}

.videov-chat__emoji__category{
	padding: 10px; 
}


.emoji_title{
	color: #4a4a4a;
    font-family: Proxima;
    opacity: 0.8;
    line-height: 16px;
    font-size: 14px;
	margin-bottom: 5px;
}

.videov-chat__emoji__category__list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}

.counter_role_box{
	cursor:pointer;
}

#videov-chat__viewers {
   align-self: flex-start;
    color: #000;
    width: 100%;
    overflow-y: auto;
    background: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.188859);
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: -55px;
    z-index: 50;
    max-width: 274px;
}
.videov-chat__viewers__list__header {
    display:none;
}
.videov-chat__viewers {
    align-self: flex-start;
    color: #000;
    width: 100%;
    overflow-y: auto;
}
.videov-chat__viewers_box{
	padding:30px;
	height: 484px;
}
.videov-chat__viewers_title{
	line-height: 16px;
    font-size: 14px;
    color: #4A4A4A;
    mix-blend-mode: normal;
    opacity: 0.4;
    font-family: Proxima;
    margin-bottom: 5px;
}

.videov-chat__viewers_counter{
	line-height: 16px;
    font-size: 12px;
    color: #4B93CD;
    mix-blend-mode: normal;
    opacity: 0.8;
    font-family: Proxima;
    margin-bottom: 20px;
    font-weight: 700;
}

.videov-chat__viewer__photo{
	width: 26px;
    height: 26px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
	margin-right: 13px;
}

.videov-chat__viewer__photo img{
	max-width:100%;
}
.videov-chat__viewer_box{
	display: flex;
    justify-content: flex-start;
    align-items: center;
	margin-bottom: 5px;
}

.videov-chat__viewers_box_list{
	height: 370px;
    overflow-y: auto;
}

#videov-chat__content{
	
}
.videov-chat__content__messages_before{
	width: 100%;
    max-width: 430px;
    background: #fff;
    height: 20px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

.chat_notyfy_circle{
	width:118px;
	height:118px;
	display:flex;
	justify-content:center;
	align-items:center;
	background:#F3F3F3;
	position:relative;
	border-radius: 200px;
}
.video_box_notify{
	display:none;
	justify-content:flex-start;
	align-items:center;
	position:fixed;
	right: 55px;
    top: 55px;
    z-index:200;
    cursor: pointer;
}
.video_box_notify.chat_muted{
    display:none !important;
}
.FullscreenVideoMode .video_box_notify{
	display:flex;
}
.chat_notyfy_number{
	color: #fff;
    width: 46px;
    height: 46px;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #FAD961 0%, #F76B1C 100%);
    position: absolute;
    top: -7px;
    right: -7px;
    border-radius: 100px;
	cursor:pointer;
}
.left_arr_chat{
	margin-right:25px;
	cursor:pointer;
}
.videov-chat_mes_box_muted{
	display:none;
	width: 100%;
    background-color: #fff;
    height: 100%;
    flex-direction: column;
    border-radius: 24px;
    align-items: flex-start;
    justify-content: flex-end;
    padding-bottom: 50px;
	background-image: url(/assets/img/videovchat/muted.svg);
    background-repeat: no-repeat;
    background-position: center 40%;
    background-size: 50%;

}
.muted_chat_text_1{
	line-height: normal;
    font-size: 24px;
    color: #4B93CD;
    mix-blend-mode: normal;
    opacity: 0.2;
    font-weight: 700;
    font-family: Proxima;
    padding-left: 65px;
    margin-bottom: 25px;
    margin-top: 55px;
}
.muted_chat_text_2{
    line-height: normal;
    font-size: 24px;
    color: #4B93CD;
    mix-blend-mode: normal;
    opacity: 0.2;
    font-weight: 700;
    font-family: Proxima;
    padding-left: 65px;
    max-width: 254px;
}

.chars_counter_box{
    margin-left: 20px;
    margin-right: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 16px;
    font-size: 12px;
    color: #4A4A4A;
    mix-blend-mode: normal;
    opacity: 0.3;
    font-family: Proxima;
}
.chars_counter_box span{
	width:20px;
	text-align: right;
}


div[role="baloon"]{
	color: #000;
    width: 165px;
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.188859);
    border-radius: 2px;
    position: absolute;
    bottom: 65px;
    left: 51px;
    z-index: 10;
    max-width: 165px;
}

.videov-chat__inputBaloon {
    color: #000;
	width: 100%;
}
.baloon_box{
	width: 100%;
	padding: 15px;
}
 .videov_btn_counter .active_switcher span{
	color: #4B93CD;
    opacity: 0.8;
    font-weight: 700;
}

.videov-chat__message__text .emojione,
.videov-chat__textarea .emojione{
    width: 22px;
}